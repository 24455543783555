@import '../../style/variables.scss';

.submitWrapper {

  width: 85vw;

  // @media (max-width: 480px) {
  //   width: 400px;
  //   // padding: 0 8px;
  // }
  // @media (max-width: 395px) {
  //   width: 350px;
  //   // padding: 0 20px;
  // }

  .supportedCardsLink {
    color: white;
    position: absolute;
    z-index:100;
    padding: 8px;
    border-radius: 8px 0px 8px 0px;
    background: white;
    font-weight: bold;
    width:220px;

    @media(max-width: 600px) {
      display:none;
    }
  }
}


.submitCodes {
  position: relative;
  // z-index: 999;
  display: flex;
  width: 100%;
  border-radius: $border-radius-table;
  // background: #f7f7f9;
  background: #fcfcfd;
  box-shadow: $box-shadow-table;
  margin-bottom: 56px;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .platformsSelector {
    width: 220px;
    border-radius: $border-radius-table 0 0 $border-radius-table;
    background-color: $color-white;

    @media (max-width: 600px) {
      background-color: initial;
      width: 100%;
    }

    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
    }

    .selector {
      outline: none !important;
      visibility: hidden !important;
      // padding-left: 1px;
      width: 220px;

      @media (max-width: 600px) {
        margin: 25px 25px 0;
        outline: initial !important;
        visibility: initial !important;
        width: 100% !important;
        max-width: 400px !important;
      }

      @media (max-width: 500px) {
        width: unset !important;
        max-width: unset;
      }

      @media (max-width: 480px) {
        // padding: 0 64px;
        // width: 270px;
        width: 350px !important;
        // padding: 0;
        // max-width: 500px !important;
        // margin: 13px 13px 0;
      }
      @media (max-width: 395px) {
        // width: 350px !important;
        max-width: 350px !important;
        // margin: 13px 13px 0;
      }

      :global(.ant-select-open) {
        :global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
            .ant-select-selector) {
          // border-color: $color-main !important;
          box-shadow: $box-shadow-input !important;
        }
      }

      :global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
        &:hover {
          border-color: $color-green !important;
        }
      }
    }
  }

  .wrapper {
    flex-grow: 4;
    display: flex;
    justify-content: center;
    padding: 25px;

    @media (max-width: 1024px) {
      // flex-direction: column;
    }

    @media (max-width: 480px) {
      width: 400px;
      align-self: center;
      // padding: 13px !important;
    }

    @media (max-width: 395px) {
      width: 350px;
      align-self: center;
      // padding: 13px !important;
    }

  }
}

.supportedCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  // width: 50%;
  background: transparent;

}





  
     
