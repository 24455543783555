@import '../../../style/variables.scss';


.radioGroup {
  margin-bottom: 24px;
  width: auto;

  .radio {
    height: 44px;
    line-height: 18px;
    text-align:start;
    padding-top: 15px;
    // display: flex;
    justify-content: center;
    transition-duration: 0.7s;

    &:first-of-type {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &:last-of-type {
      border-radius: 0 $border-radius $border-radius 0;
    }

    &:only-child {
      border-radius: $border-radius;
    }
  }

  :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
    background: $color-yellow !important;
    border-color: $color-button-grey !important;
    // font-weight: 700 !important;
  }

  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
    background-color: unset !important;
  }

  :global(.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
    box-shadow: unset !important;
  }

  
}

.link {
  color: $color-green !important;
}

.text {
  // color: $color-font-grey !important;
  font-size: 14px;
  line-height: 17px;
}

.textGrey {
  color: $color-font-grey !important;
  font-size: 10px;
  line-height: 17px;
}

.textBlue {
  color: rgba(0, 101, 194, 1);
  cursor: pointer;
}

// .valueInput {
//   // background: url('/front/public/dollar-symbol.svg') no-repeat 95% !important;
//   // background-position: right;
//   background-size: 15px !important;
//   height: 56px;
//   width: 165px;
// }
.valueInput {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 8px 0;
}
.currencyRate {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.walletInput {
  height: 44px;
}

.input {
  height: 44px;
      border-radius:8px !important;
      &:focus {
        border: 1px solid $color-green;
      }
}


.minorInput {
  height: 44px;
  width: 165px;
}

.buttonAnimated {
  margin-top: 20px;
  width: 70px;
  height: 70px;
  border-radius: 70px;
  background: $color-dark-green;
  // margin-left: 250px;
}

.button {
  width: 125px !important;
  height: 44px !important;
  border-radius: $border-radius !important;
  background: #43BA00 !important;
  transition: all 0.7s ease-out !important;
  align-self: center !important;
  border-color: transparent !important;
  // margin-top: 32px;

  span {
    color: $color-white;
    font-size: 17px;
    font-weight: 600;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  &:hover {
    background: $color-hover-gradient !important;
    background-size: 300%;
    animation: gradient 1s ease;
    transform: translate3d(0, 0, 0);
  }

  &:active {
    background: $color-active-gradient;
  }
}

.closeModal {
  display: none;

  @media (max-width: 480px) {
   display: flex;
   justify-content: center;
   margin-top: 16px;
  }
}


@keyframes ldio4nb5p2ohc8k {
  0% { transform: translate(6px,40px) scale(0); }
 25% { transform: translate(6px,40px) scale(0); }
 50% { transform: translate(6px,40px) scale(1); }
 75% { transform: translate(40px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(1); }
}
@keyframes ldio4nb5p2ohc8kr {
  0% { transform: translate(74px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(0); }
}
@keyframes ldio4nb5p2ohc8kc {
  0% { background: white }
 25% { background: white }
 50% { background: white }
 75% { background: white }
100% { background: white }
}
.ldio4nb5p2ohc8k div {
 position: absolute;
 width: 12px;
 height: 12px;
 border-radius: 50%;
 transform: translate(40px,40px) scale(1);
 background: white;
 animation: ldio4nb5p2ohc8k 2s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio4nb5p2ohc8k div:nth-child(1) {
 background: white;
 transform: translate(74px,40px) scale(1);
 animation: ldio4nb5p2ohc8kr 0.5s infinite cubic-bezier(0,0.5,0.5,1), ldio4nb5p2ohc8kc 2s infinite step-start;
}.ldio4nb5p2ohc8k div:nth-child(2) {
 animation-delay: -0.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(3) {
 animation-delay: -1s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(4) {
 animation-delay: -1.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(5) {
 animation-delay: -2s;
 background: white;
}
.loadingiospinnerellipsisxxraaph2hu9 {
 width: 56px;
 height: 56px;
 margin-left: -4px;
 overflow: hidden;
 background: none;
}
.ldio4nb5p2ohc8k {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(0.56);
 backface-visibility: hidden;
 transform-origin: 0 0; /* see note above */
}
.ldio-4nb5p2ohc8k div { box-sizing: content-box; }
