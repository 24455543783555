@import '../../style/variables.scss';

.userProfileWrapper {
  // width: 40vw !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding-top: 8px;

  // z-index: -1;

  .link {
    // margin-left: 10px;

    &:hover {
      color: $color-green !important;
    }
  }

  :global(.ant-menu-item-active) {
    a {
      color: $color-green !important;
    }
  }

  :global(.ant-menu.ant-menu-sub.ant-menu-inline) {
    box-shadow: $box-shadow-table !important;
  }

  :global(.ant-menu-inline) {
    border-right: unset;

    &:active {
      background: $color-hover-light;
    }
  }

  :global(.ant-menu-item-selected) {
    color: $color-green !important;
  }

  :global(.ant-menu-item-active) {
    color: $color-green !important;
  }

  :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
    background-color: $color-background-grey;
  }

  :global(.ant-menu-inline .ant-menu-item::after) {
    border-right: 3px solid $color-green;
  }

  :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
    &:active {
      background: $color-hover-light;
    }
    &:focus {
      background: $color-hover-light;
    }
  }

  :global(.ant-menu-submenu-title)[role='menuitem'] {
    margin: 0 !important;
    padding-right: 24px !important;
    // height: 64px !important;
    border-right: unset;
    

    @media (max-width: 890px) {
      padding-left: 0 !important;
    }

    &:hover {
      color: $color-black !important;
    }

    &:active {
      background: $color-hover-light;
      
    }

    :global(.ant-menu-submenu-arrow) {
      &::before {
        display: none;
        
      }

      &::after {
        display: none;
      }
    }
  }

  &:hover {
    color: $color-black !important;

    path {
      fill: $color-green;
    }
  }

  .userProfile {
    padding-top: 0 !important;
    display: flex;
    justify-content: center;
    // margin-top: 8px;
    align-items: center;
    // height: 64px;
    // width: 200px;
    width: auto;
    color: inherit;

    &:hover {
      color: $color-black !important;
    }

    &__authBlock {
     display: flex;
     align-items: center;
    }

    .data {
      margin-left: 17px;
      display: flex;
      flex-direction: column;

      .dataItem {
        display: flex;
        line-height: 100%;

        .balanceText {
          margin-right: 4px;
          margin-bottom: 5px;
          font-size: 13px;
        }

        .balanceAmount {
          font-size: 17px;
          font-weight: bold;
        }

        .pendingText {
          margin-right: 4px;
          font-size: 13px;
          color: inherit;
        }

        .pendingAmount {
          font-size: 15px;
          color: inherit;
        }
      }
    }
  }

  

  .header__log_in {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    padding: 0 16px 0 4px;
    margin-right: 12px;
    background-color: #E3FBD0;
    border-radius: 8px;
    width: 110px;
    height: 36px;

    a {
      color: black;
    }

    // i {
    //   background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzBGOEEwRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOCAxMi41YTYgNiAwIDEgMCAwLTEyIDYgNiAwIDAgMCAwIDEyWm0tNSAyYTMgMyAwIDAgMC0zIDN2M2EzIDMgMCAwIDAgMyAzaDEwYTMgMyAwIDAgMCAzLTN2LTNhMyAzIDAgMCAwLTMtM0gzWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
    //   height: 24px;
    //   width: 16px
    // }
  }

  .header__balance {
    align-items: center;
    display: flex;
    font-size: 14px;
    width: 1px;
    // gap: 12px;
    // margin-right: 12px;

    i {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzBGOEEwRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNOCAxMi41YTYgNiAwIDEgMCAwLTEyIDYgNiAwIDAgMCAwIDEyWm0tNSAyYTMgMyAwIDAgMC0zIDN2M2EzIDMgMCAwIDAgMyAzaDEwYTMgMyAwIDAgMCAzLTN2LTNhMyAzIDAgMCAwLTMtM0gzWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
      height: 24px;
      width: 16px
    }
  }
}
