@import '../../style/variables.scss';


    .inputs {

      :global(.ant-select-show-arrow .ant-select-borderless) {
        display: none;
      }

      display: flex;
      flex-direction: column;
      // max-width: 600px;
      width: 70%;
      // height: 650px;

      @media (max-width: 480px) {
        width: auto;
      }

      textarea {
        resize: none;
        border-radius: $border-radius;

        &:hover {
          border-color: $color-green !important;
        }

        &:focus {
          box-shadow: $box-shadow-input;
        }
      }

      :global(.ant-form-item-explain-error) {
        color: $color-font-grey !important;
      }

      .radioGroup {
        margin-bottom: 16px;

        .radioGroupInst {
          height: 48px;
          width: 160px;
          line-height: 18px;
          text-align:start;
          padding-top: 15px;
          justify-content: center;
          transition-duration: 0.7s;

          p {
            font-size: 12px;
          }
        }

        .radio {
          height: 64px;
          width: 100px;
          line-height: 18px;
          text-align:start;
          padding-top: 15px;
          // display: flex;
          justify-content: center;
          transition-duration: 0.7s;
          // margin: 2px;
          // border-radius: 4px;

          

          

          // &:first-of-type {
          //   border-radius: $border-radius 0 0 $border-radius;
          // }

          // &:last-of-type {
          //   border-radius: 0 $border-radius $border-radius 0;
          // }

          // &:only-child {
          //   border-radius: $border-radius;
          // }
        }

        :global(.ant-radio-button-checked) {
          background: #ffe699 !important;
        }

        :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
          background: $color-button-grey !important;
          border-color: $color-button-grey !important;
          font-weight: 700 !important;
        }

        :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
          background-color: unset !important;
        }

        :global(.ant-radio-group-solid
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
          box-shadow: unset !important;
        }
      }

      .radioGroupReward {
      
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 24px;
        width: auto;

        @media (max-width: 480px) {
          width: max-content;
        }
  
        .radioReward {
          width: 240px;
          height: 88px;
          // width: 100%;
          // justify-content: flex-start;
          // line-height: 55px;
          // text-align: center;
          transition-duration: 0.7s;

          @media (max-width: 954px) {
            max-width: 240px;
          }

          @media (max-width: 480px) {
            width: 170px;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 13px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: solid 1px #6371ED;
            // box-shadow: inset 0 0 0 0.2375em #6371ED;
            transition: 0.25s ease;
            margin: 0;
          }
  
          &:first-of-type {
            border-radius: $border-radius 0 0 $border-radius;
          }
  
          &:last-of-type {
            border-radius: 0 $border-radius $border-radius 0;
          }
  
          &:only-child {
            border-radius: $border-radius;
          }

          p {
            font-style: normal;
            // font-weight: 400;
            font-size: 15px;
            margin-bottom: unset !important;
            margin-left: 20px;
            line-height: 1.3rem;
            &:first-of-type {
              margin-top: 10px;
              
              
            }
            
            &:nth-child(2) {
              font-size: 10px;
              font-weight: 400;
              opacity: 0.5;
            }
            &:last-of-type {
              font-size: 12px;
              font-weight: 500;
            }
            
          }
        }
  
        :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
          background: $color-button-grey !important;
          border-color: $color-button-grey !important;
          font-weight: 700 !important;
          &:after {
                content: "";
                display: block;
                position: absolute;
                top: 13px;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                border: solid 1px #6371ED;
                box-shadow: inset 0 0 0 0.2775em #6371ED;
                transition: 0.25s ease;
                margin: 0;
              }
        }
  
        :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
          background-color: unset !important;
        }
  
        :global(.ant-radio-group-solid
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
          box-shadow: unset !important;
        }
      }
    }

    .calculator {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .denominationsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      
      .denominations {
        padding: 8px;
        margin: 6px;
        border: 1px solid black;
        border-radius: $border-radius;
      }
    }
    
    .denominationsAlert {
      
    }
    

    .rewardRadioGroup {
      // max-width: 400px;
      height: 88px;
      padding: 0px;
      margin-bottom: 8px;
      background: #fff;
      border: 1px solid rgba(21, 24, 33, 0.1);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

        input[type="radio"] {
          display:none;
          &:checked + label:before{
            background: white;
            box-shadow: inset 0 0 0 0.4375em #6371ED;
          }
    
          &:checked + label {
            background: rgba(30, 34, 72, 0.1);
            transition: ease-in 0.2s;
            span {
              font-weight: 600;
            }
          }
        }

        .instantRadio {
          width: stretch;
          height: 88px;

          &:only-child {
            max-width: 200px;
          }

          &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 1px #CDD1D5;
            transition: 0.25s ease;
            margin: 10px 0px 0px 10px;
          }
        }

        p {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;
          position: relative;
          left: 40px;
          top: -16px;

            &:first-of-type {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            opacity: 0.5;
        }
        }

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          padding-bottom: 5px;
          position: relative;
          left: 40px;
          top: -20px;
        }

    }

    .comment {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      opacity: 0.5;
    }

    .xboxLiveRestriction {
      color: red;
      border: 1px solid red;
      border-radius: 4px;
      font-size: 12px;
      padding: 8px;
      margin-bottom: 12px;
    }

    .instructions {
      // padding-left: 25px;
      margin-bottom: 12px;
      p {
        margin-bottom: 0 !important;
      }

      @media (max-width: 1024px) {
        padding-left: 0;
        width: 100%;
        max-width: 100%;
      }

      p {
        color: $color-font-grey;
      }
    }

    // :global(.ant-btn-primary) {
    //   border-color: $color-main !important;
    // }

    .submitButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buttonAnimated {
      margin-bottom: 20px;
      width: 70px;
      height: 70px;
      border-radius: 70px;
      background: $color-gradient;
      margin-left: 172px;
    }

    .buttonDisabled {
      margin-top: 20px;
      width: 100%;
      height: 56px;
      border-radius: $border-radius;
      border-color: unset !important;
      background: rgb(222, 222, 222);
      transition: all 0.7s ease-out;
      align-self: center;

      span {
        // color: $color-white !important;
        font-size: 17px;
        font-weight: 700;
      }
    }

    .buttonText {
      // margin: 18px 0 0 20px;
      font-size: 14px;
    }

    .button {
      margin-top: 20px !important;
      width: 100%!important ;
      height: 56px !important;
      border-radius: $border-radius !important;
      background: $color-green-button !important;
      transition: all 0.7s ease-out !important;
      align-self: center !important;

      span {
        color: $color-white !important;
        font-size: 17px;
        font-weight: 700;
      }

      &:hover {
        background: $color-hover-gradient !important;
        background-size: 300% !important;
        animation: gradient 1s ease-out !important;
        // transform: translate3d(0, 0, 0) !important;
      }

      &:active {
        background: $color-active-gradient;
      }
    }

    .menuGroup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // justify-content: space-around;

      // @media (max-width: 360px) {
      //   inline-size: auto;
      //   display: inline;
      // }

      // span {
      //   opacity: 0.5;
      // }
    }
    .menu * {
      box-sizing: border-box;
    }
    
    .menu {

      max-width: 400px;
      display: inline;
      justify-content: space-evenly;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 4px;
      padding: 0;
      overflow: hidden;
      list-style-type: none;
    
      li {

        @media(max-width: 360px) {
          display: flex;
          justify-content: center;
        }

        float: left;
        margin: 0 1.5em;
        padding: 0;
        
      }
    
      a {
        display: block;
        padding: 0.5em 0;
        // color: black;
        position: relative;
        letter-spacing: 1px;
        text-decoration: none;
    
        &:before, &:after {
          position: absolute;
          -webkit-transition: all 0.35s ease;
          transition: all 0.35s ease;
        }
    
        &:before {
          bottom: 0;
          display: block;
          height: 2px;
          width: 0%;
          content: "";
          background-color: $color-green;
        }
    
        &:after {
          left: 0;
          top: 0;
          padding: 0.5em 0;
          position: absolute;
          content: attr(data-hover);
          color: $color-green;
          white-space: nowrap;
          max-width: 0%;
          overflow: hidden;
        }
      }
      
      a:hover:before, .menuCurrent a:before {
        opacity: 1;
        width: 100%;
      }

      a:hover:after, .menuCurrent a:after {
        max-width: 100%;
      }
    }
  




.supportedCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  // width: 50%;
  background: transparent;

  .supportedCardsPlatforms {
    width: 43vw;
    text-align: center;
    empty-cells: show;
    background: #f7f7f9;
    box-shadow: $box-shadow-table;
    border-radius: $border-radius-table;
    margin: 12px;
    // height: auto;

    table {
      box-sizing: border-box;
      width: 100%;

    }

    .supportedTableHeader {
      display: flex;
      flex-direction: row;
      text-align: center;
      padding: 20px 0px 0px 20px;

      .supportedRadioGroup {
        margin-left: 8px;
        padding: 0;


        .supportedChangeButton {
          
          
          text-align: center;
          transition-duration: 0.7s;
          // border-radius: $border-radius

          &:first-of-type {
            border-radius: $border-radius 0 0 $border-radius;
          }

          &:last-of-type {
            border-radius: 0 $border-radius $border-radius 0;
          }

          &:only-child {
            border-radius: $border-radius;
          }
        }

        :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
          background: $color-button-grey !important;
          border-color: $color-button-grey !important;
        }

        :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
          background-color: unset !important;
        }

        :global(.ant-radio-group-solid
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
          box-shadow: unset !important;
        }
      }


      h1 {
        padding-right: 10px;
      }

      img {
        padding-right: 10px;
        max-width: 100%;
        height: 100%;
        align-self: center;
      }
    }
      

    .supportedTableWrapper {
      display: flex;
      justify-content: flex-start;
      padding: 15px;
    }
    
    @media (max-width: 1024px) {
      width: 100vw;
    }
  }

  thead {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    &:before {
      content:"";
      display:block;
      line-height:1em;
  }
  } 

  th {
    text-align:left;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    opacity: 0.5;
  }

  td {
    text-align:left;
    padding: 1px 1px;
    &:nth-child(2) {
      font-weight: 600;
    }
  }

  .supportedCardsCur {
    width: 100%;
    text-align: center;
    background: rgba(30, 34, 72, 0.05);
    border-radius: 4px;
    margin: 0px 5px 0px 0px;
    font-weight: 400;
    &:hover {
      cursor:default;
      background: $color-gradient;
      color:#f7f7f9 !important;
    }
  }

  .supportedCardsAll {
    width: 100%;
    text-align: center;
    margin: 0px 5px 0px 0px;
    font-weight: 400;
  }

  .supportedCardsRow {
    &:hover {
      background: rgba(30, 34, 72, 0.05);
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 99% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@keyframes ldio4nb5p2ohc8k {
  0% { transform: translate(6px,40px) scale(0); }
 25% { transform: translate(6px,40px) scale(0); }
 50% { transform: translate(6px,40px) scale(1); }
 75% { transform: translate(40px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(1); }
}
@keyframes ldio4nb5p2ohc8kr {
  0% { transform: translate(74px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(0); }
}
@keyframes ldio4nb5p2ohc8kc {
  0% { background: white }
 25% { background: white }
 50% { background: white }
 75% { background: white }
100% { background: white }
}
.ldio4nb5p2ohc8k div {
 position: absolute;
 width: 12px;
 height: 12px;
 border-radius: 50%;
 transform: translate(40px,40px) scale(1);
 background: white;
 animation: ldio4nb5p2ohc8k 2s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio4nb5p2ohc8k div:nth-child(1) {
 background: white;
 transform: translate(74px,40px) scale(1);
 animation: ldio4nb5p2ohc8kr 0.5s infinite cubic-bezier(0,0.5,0.5,1), ldio4nb5p2ohc8kc 2s infinite step-start;
}.ldio4nb5p2ohc8k div:nth-child(2) {
 animation-delay: -0.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(3) {
 animation-delay: -1s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(4) {
 animation-delay: -1.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(5) {
 animation-delay: -2s;
 background: white;
}
.loadingiospinnerellipsisxxraaph2hu9 {
 width: 56px;
 height: 56px;
 margin-left: -4px;
 overflow: hidden;
 background: none;
}
.ldio4nb5p2ohc8k {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(0.56);
 backface-visibility: hidden;
 transform-origin: 0 0; /* see note above */
}
.ldio-4nb5p2ohc8k div { box-sizing: content-box; }


