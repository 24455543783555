@import '../../style/variables.scss';

.modal {
  width: 639px !important;
  z-index: 999;
}

.card {
  position: relative;
  // z-index: 999;
  max-width: 100%;
  max-height:100%;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 17px;
  display: flex;
  justify-content: space-evenly;
  background: $color-white !important;

  @media (max-width: 768px) {
    display: block;
  }


  
  .balanceBlock {
    display: contents;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content:flex-start;
    }

    @media (max-width: 540px) {
      display: flex;
      flex-direction: column;
      justify-content:flex-start;
    }
}
}

.link {
  color: $color-green !important;
}

.text {
  align-self: center;
  width: 100%;
}

.withdrawalBlock {
  display: flex;
  align-items: center;

}

.pendingBalance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;

  .pendingText {
    font-size: 15px;
    color: inherit;
    line-height: 20px;
    color: $color-font-grey !important;
  }

  .pendingAmount {
    font-size: 20px;
    color: inherit;
    line-height: 32px;
    font-weight: bold;
    color: $color-font-grey !important;
  }
}

.availableBalance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  margin-right: 26px;

  .availableText {
    font-size: 15px;
    line-height: 20px;
  }
  
  .availableAmount {
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
  }

}

.button {
  width: 127px !important;
  height: 48px !important;
  border-radius: $border-radius !important;
  background: $color-green-button !important;
  transition: all 0.7s ease-out !important;
  align-self: center !important;

  span {
    color: $color-white !important;
    font-size: 17px;
    font-weight: 600;
  }

  &:hover {
    background: $color-hover-gradient !important;
    background-size: 300% !important;
    animation: gradient 1s ease !important;
    transform: translate3d(0, 0, 0) !important;
  }

  // &:active {
  //   background: $color-active-gradient;
  // }
}
