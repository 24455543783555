@import '../../style/variables.scss';

.tag {
  height: 24px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 9px;
  border-radius: $border-radius;
  background-color: #dbdbdb;

  @media (max-width: 760px) {
    padding: 3px;
  }

  &[data-color='red'] {
    background-color: rgba(254, 74, 106, 0.1);
    color: #fe4a6a !important;
  }
  &[data-color='green'] {
    background-color: rgba(49, 194, 124, 0.1);
    color: #31c27c !important;
  }
  &[data-color='yellow'] {
    background-color: rgba(255, 208, 83, 0.1);
    color: #f0b20d !important;
  }

  // svg {
  //   margin-right: 5px;
  // }
}
