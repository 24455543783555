@import '../../style/variables.scss';


.header {

  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 24px auto;
  align-self: center;
  // width: 90vw;
  width: 85vw;
  max-width: 1280px;
  height: 56px;


  .leftBlock {
    // float: left;
    flex: 1;
    display: flex;
    align-items: center;
    // background-color: green;

    .swapLogoWrapper {
      display: flex;
      align-items: center;

      b {
        margin-left: 8px;
      }
    }
  }

  .centerBlock {
    flex: 1.5;
    // background-color: red;

    // .navigation {
  //   display: flex;
  //   align-self: center;
  //   padding: 0 8px;

  //   div {
  //       a {
  //         color: black;
  //         margin: 0 24px;
  //         text-decoration: none;
  //       }
  // }
  // }

  .navigation {
    display: none;

    @media (min-width: 890px) {
      display: flex;
    }

    :global(.ant-menu-item) {
      // width: 96px;
      
      // padding: 0px 24px !important;

      a {
        font-size: 14px;
        // text-align: center;
        // font-weight: bold;
      }

      &:hover::after {
        border-bottom: 2px solid $color-green !important;
      }
    }

    :global(.ant-menu-item-selected) {
      a {
        color: $color-green !important;
      }
      &::after {
        border-bottom: 2px solid $color-green !important;
      }
    }

    :global(.ant-menu-item-active) {
      a {
        color: $color-green !important;
      }
    }
  }
  :global(.ant-menu-overflow) {
    // justify-content: center !important;
  }
  }

  .rightBlock {
    // flex: 1;
    // background-color: green;
      height: 100%;
      // z-index: 1;
      // display: flex;
      // justify-content: center;
      // float: right;
      // position: absolute;
      top: 0;
      right: 0;
      // width: auto;
  }

  
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &_userName {
    opacity: 0.7;
    // color: $color-main;
    // text-decoration:;
    
  }
  
}

