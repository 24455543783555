.data {
  @media (min-width: 890px) {
    // margin-left: 17px;
  }
  display: flex;
  flex-direction: column;

  .dataItem {
    display: flex;
    line-height: 100%;

    .balanceText {
      margin-right: 4px;
      margin-bottom: 5px;
      font-size: 13px;
    }

    .balanceAmount {
      font-size: 17px;
      font-weight: bold;
    }

    .pendingText {
      margin-right: 4px;
      font-size: 13px;
      color: inherit;
    }

    .pendingAmount {
      font-size: 15px;
      color: inherit;
    }
  }
}
