/* @import './style/variables.scss';
@import './style/normalize.css'; */
@import '~antd/dist/antd.css';

* {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* // font-display: swap; */
  color: #32343c;
  /* // letter-spacing: -0.02em; */
  /* // border: 1px solid red; */

}

html {
  scroll-behavior: smooth;
}

input {
  caret-color: transparent !important;
}

svg[data-icon="right"]{
  fill: white !important
}

svg[data-icon="star"]{
  fill: #FFCC33 !important
}



*,
*::before,
*::after {
  box-sizing: border-box;
}

[id='__next'] {
  height: 100%;
}

.App {
  margin: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notification-container {
  width: 85vw;
  align-self: center;
  text-align:center;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
 
}

.notification {
  font-size: 1.2em;
  color: #32343c;
  word-wrap:break-word;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
}


.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #E3E4E8;
  border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #E3E4E8;
}

.ant-checkbox-checked::after {
  border: 1px solid #E3E4E8;
  border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid rgba(115, 119, 140, 1);
  border-top: 0;
  border-left: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
  border-radius: 8px;
}

.ant-pagination-options .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px;
  /* border-radius: 8px;  */
}

/* Target the .ant-select-single .ant-select-selector .ant-select-selection-item and .ant-select-single .ant-select-selector .ant-select-selection-placeholder elements */
/* .ant-pagination-options
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: unset;
} */

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 1px solid transparent;
}

.ant-menu-item-active, a:hover {
    color: #43BA00 !important;
}

.ant-input-affix-wrapper {
 height: 48px;
 border-radius: 0px 8px 8px 0px;
}

li.ant-pagination-options
  div.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow
  .ant-select-selector::after {
    line-height: unset !important;
  }

/* Target only the .ant-select-single .ant-select-selector .ant-select-selection-item elements */
.ant-pagination-options
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  top: 0px;
}

.ant-select-selection-search-input {
  position: absolute;
  /* left: 12px; */
  top: 7px;
}

.ant-select-selection-placeholder {
  position: absolute;
  left: 12px;
  top: 7px;
}

.ant-select-selection-item {
  position: absolute;
  left: 4px;
  top: 7px;
}


.ant-input::placeholder,
textarea.ant-input::placeholder {
  color: #32343c;
  opacity: 0.5;
}
.ant-input::-webkit-input-placeholder,
textarea.ant-input::-webkit-input-placeholder {
  color: #32343c;
  opacity: 0.5;
}
.ant-input::-moz-placeholder,
textarea.ant-input::-moz-placeholder {
  color: #32343c;
  opacity: 0.5;
}

.ant-menu {
  background: transparent !important;
  border-bottom: 2px solid transparent;
}

.ant-menu .ant-menu-sub {
  background: white !important;
}

.mainLayout {
  min-height: 100%;
  /* // background: linear-gradient(180deg, #E6FFCC 0%, #FFFFFF 40.05%); */
  background: white;
  z-index: 1;
  /* // background: #f4f4f6 !important;
  // background: #fff !important; */
}

.mainLayout__content {
  z-index: 1;
  /* // margin: 0 auto; */
  padding: 13px 0 0 !important;
  /* // max-width: 1302px; */
  /* width: 100%; */

  @media (min-width: 480px) {
    /* // padding: 16px 16px 0 !important; */
  }

  @media (min-width: 890px) {
    /* // padding: 24px 24px 0 !important; */
  }

  @media (min-width: 1340px) {
    /* // padding: 24px 0 0 !important; */
  }
}

.unauthenticated {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.unauthenticated span {
  margin-right: -5px;
}

.unauthenticated a {
  text-transform: lowercase;
}

.ant-message-notice-content {
  z-index: 100500;
}
.ant-space-item {
  position: relative;
  /* // z-index: 999; */
}
/* TITLES */
h1.ant-typography {
  font-weight: 700;
  position: relative;
  /* // z-index: 999; */
  @media (max-width: 480px) {
    margin-right: 13px !important;
    margin-left: 13px !important;
  }
}

h2.ant-typography {
  position: relative;
  /* // z-index: 999; */
  @media (max-width: 480px) {
    margin-right: 13px !important;
    margin-left: 13px !important;
  }
}

h5.ant-typography {
  margin-top: 0.5em !important;
  margin-bottom: 0.3em;
}
p {
  margin-bottom: 1.5em;
}
.greySpan {
  color: #33445580 !important;
}
/* END OF TITLES */

/* TABLES */
.ant-table-thead > tr > th {
  background: #fff;
  color: #33445580 !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}
.ant-table table {
  border-radius: 8px 8px 0 0;
}
.ant-table-container {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ant-table {
  border-radius: 8px;
  box-shadow: 0px 7px 10px rgba(78, 98, 174, 0.05);
}
/* .ant-table-cell .tableLink,
.tableLink {
  word-break: break-all;
  color: #6371ed !important;

  &:hover {
    text-decoration: underline;

    span {
      text-decoration: underline;
    }
  }
  &:active {
    color: #8491ff !important;

    span {
      color: #8491ff !important;
    }
  }
  span {
    color: #6371ed !important;
  }
} */

.ant-table-cell .tableLink,
.tableLink {
  word-break: break-all;
  color: #43BA00 !important;
}

.ant-table-cell .tableLink:hover,
.tableLink:hover {
  text-decoration: underline;
}

.ant-table-cell .tableLink:hover span,
.tableLink:hover span {
  text-decoration: underline;
}

.ant-table-cell .tableLink:active,
.tableLink:active {
  color: rgba(49, 194, 124, 1) !important;
}

.ant-table-cell .tableLink:active span,
.tableLink:active span {
  color: rgba(49, 194, 124, 1) !important;
}

.ant-table-cell .tableLink span,
.tableLink span {
  color: #43BA00 !important;
}



.ant-table-cell .tableComment,
.tableComment {
  font-size: 12px;
}

/* .ant-table-row {
  .ant-table-cell .amount,
  .amount  {
    padding-bottom: 36px !important;
  }

  .ant-table-cell .wallet,
  .wallet  {
    padding-bottom: 36px !important;
  }

  .ant-table-cell .bank,
  .bank  {
    padding-bottom: 16px !important;
  }
  
} */

.ant-table-row .ant-table-cell .amount,
.ant-table-row .amount,
.ant-table-cell .amount,
.amount {
  padding-bottom: 36px !important;
}

.ant-table-row .ant-table-cell .wallet,
.ant-table-row .wallet,
.ant-table-cell .wallet,
.wallet {
  padding-bottom: 36px !important;
}

.ant-table-row .ant-table-cell .bank,
.ant-table-row .bank,
.ant-table-cell .bank,
.bank {
  padding-bottom: 16px !important;
}

.ant-tag.ant-tag-has-color {
  color: #fff !important;
}
.ant-spin-dot-item {
  background-color: #43BA00 !important;
}

/* .ant-table-summary {
  border-radius: 0 0 8px 8px;

  td {
    border-radius: 0 0 8px 8px;
  }
} */

.ant-table-summary {
  border-radius: 0 0 8px 8px;
}

.ant-table-summary td {
  border-radius: 0 0 8px 8px;
}

.codePackMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  font-size: 14px;
  /* height:120px; */
}

.codePackMobileText {
  /* font-size: 12px; */
}

/* END OF TABLES */

/* // .ant-select-dropdown {
//   z-index: 1;
// } */

/* PAGINATION */
/* .ant-pagination.ant-table-pagination {
  li {
    border-radius: 4px !important;

    &:hover {
      border-color: #6371ed !important;
    }
    button {
      border-radius: 4px !important;
    }
  }
  .ant-select:not(.ant-select-disabled) {
    &:hover .ant-select-selector {
      border-color: #6371ed !important;
    }
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: linear-gradient(90deg, #6371ed 0%, #935cec 100%) !important;
    border-color: #6371ed !important;

    a {
      color: #fff !important;
    }
  }
  .ant-pagination-prev:hover .ant-pagination-item-link {
    border-color: #6371ed !important;
  }
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #6371ed !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #6371ed !important;
    box-shadow: 0 0 0 2px rgb(99 113 237 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
} */

.ant-pagination.ant-table-pagination li {
  border-radius: 4px !important;
}

.ant-pagination.ant-table-pagination li:hover {
  border-color: #43BA00 !important;
}

.ant-pagination.ant-table-pagination li button {
  border-radius: 4px !important;
}

.ant-pagination.ant-table-pagination .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #43BA00 !important;
}

.ant-pagination.ant-table-pagination .ant-pagination-item-active {
  font-weight: 500;
  background: linear-gradient(90deg, #43BA00 0%, #338a00 100%) !important;
  border-color: #43BA00 !important;
}

.ant-pagination.ant-table-pagination .ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination.ant-table-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination.ant-table-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #43BA00 !important;
}

.ant-pagination.ant-table-pagination .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #43BA00 !important;
  box-shadow: 0 0 0 2px rgb(99 113 237 / 20%);
  border-right-width: 1px;
  outline: 0;
}


/* END OF PAGINATION */
.ant-input:focus, .ant-input-focused {
  border-color: #43BA00 !important;
  box-shadow: 0 0 0 2px #43BA00 !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #43BA00 !important;
  box-shadow: 0 0 0 2px #43BA00 !important;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  box-shadow: 0 0 0 1px red !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 1px red !important;
}

/* // .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//   border-color: #6371ed !important;
//   box-shadow: 0 0 0 2px #6371ed;
//   border-right-width: 1px;
//   outline: 0;
// } */

.ant-modal-body {
  @media(max-width: 480px) {
    padding: 16px;
  }
}

/* .ant-btn-primary[disabled] {
  background: rgba(128, 128, 128, 1) !important;
  span {
    color: rgba(255, 255, 255, 0.6) !important;
  }
} */

.ant-btn-primary {
  border-color: #43BA00;
}

.ant-btn-primary[disabled] {
  background: rgba(128, 128, 128, 1) !important;
}

.ant-btn-primary[disabled] span {
  color: rgba(255, 255, 255, 0.6) !important;
}

.ant-btn-primary:hover {
  border-color: #43BA00 !important;
}

/* SUBMIT CODES PAGE STYLES */
.ant-select-item-option-content {
    display: flex;
    align-items: center;
}

.ant-select-item-option-active {
  border-radius: 4px;
}
/* .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f4f4f6;
  border-radius: 4px;
  
  .ant-select-item-option-content {
    color: #fff !important;
  }
} */

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f4f4f6;
  border-radius: 4px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-content {
  /* color: #fff !important; */
}
/* END OF SUBMIT CODES PAGE STYLES */

/* HIDE LONG NAMES AT THE TABLE USING TOOLTIP AND ELLIPSIS */
/* td.ellipsis {
  span {
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    line-height: 20px;

    @media (max-width: 1350px) {
      width: 150px;
      word-break: break-all;
    }
    @media (max-width: 1024px) {
      width: 100px;
      word-break: break-all;
    }
    @media (max-width: 940px) {
      width: 100px;
    }


    @media (max-width: 580px) {
      width: 200px;
    }
  }
  .ant-table-filter-trigger-container {
    width: 2.3em;
  }
} */


td.ellipsis span {
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  line-height: 20px;
}

/* @media (max-width: 1350px) {
  td.ellipsis span {
    width: 150px;
    word-break: break-all;
  }
} */

/* @media (max-width: 1024px) {
  td.ellipsis span {
    width: 100px;
    word-break: break-all;
  }
} */

/* @media (max-width: 940px) {
  td.ellipsis span {
    width: 100px;
  }
} */

@media (max-width: 580px) {
  td.ellipsis span {
    width: 200px;
  }
}

td.ellipsis .ant-table-filter-trigger-container {
  width: 2.3em;
}

/* // .ant-table-cell-ellipsis {
//   display: block;
//   width: 300px;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   cursor: pointer;

//   @media (max-width: 1350px) {
//     width: 150px;
//   }
//   @media (max-width: 1024px) {
//     width: 100px;
//   }
//   @media (max-width: 940px) {
//     width: 80px;
//   }
//   @media (max-width: 540px) {
//     width: 60px;
//   }
// } */
.ant-table-column-sorters-with-tooltip {
  display: none !important;
}
.ant-tooltip-inner {
  color: #fff !important;
}

.amount {
  font-weight: 600;
}
/* END OF HIDE LONG NAMES AT THE TABLE USING TOOLTIP AND ELLIPSIS */

/* .anticon.anticon-close-circle.ant-alert-icon {
  svg {
    fill: red !important;
  }
} */

.anticon.anticon-close-circle.ant-alert-icon svg {
  fill: red !important;
}

.ant-result-icon svg {
  fill: rgb(226, 108, 108) !important;
}

.ant-btn-sm:not(.ant-btn-link) > span {
  color: white !important;
}


.anticon {
  /* svg {
    // fill: #6371ed;
    // opacity: 0.7;
  } */
}

.rc-virtual-list-holder {
 /* max-height: 590px !important; */
}

/* FAQ PAGE */
.faq {
  position: relative;
  z-index: 999;
  @media (max-width: 480px) {
    padding: 0 13px;
  }
}
/* END OF FAQ PAGE */

.react-multiple-carousel__arrow {
  background: transparent;
}

.react-multiple-carousel__arrow--left {
  left: calc(4% - 100px)
}

.react-multiple-carousel__arrow--right {
  right: calc(4% - 100px)
}
/* 
.react-multi-carousel-track::before {
  content: '';
  position: relative;
  height: 250px;
  width: 0px;
  opacity: 0.3;
  border: 1px solid #1EA112;
  right: calc(4% + 35px);
  bottom: 100px;
} */

.react-multi-carousel-item:not(.react-multi-carousel-item--active) {
  opacity: 0.7;
  /* transform: scale(0.9); */
}

@media (max-width: 520px) { 
  .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
    /* opacity: 0.7; */
    transform: scale(0.8);
  }
}



.react-multiple-carousel__arrow::before {
  color: #1EA112;
  font-size: 36px;
}

.react-multiple-carousel__arrow:hover {
  background: transparent;
  /* left: calc(1% - 100px); */
}

@media (max-width: 820px) {
.react-multi-carousel-item {
  /* width: 30vw !important; */
}
}

@media (max-width: 520px) {
  .react-multi-carousel-item {
    /* width: 40vw !important; */
    /* transition: all 0.5s ease-out; */
    transition:linear 0.5s;
  /* transition-duration: 1s; */
  }
  }

  .ant-descriptions-row:nth-of-type(odd) {
    background: #ffe699;
  }
  

