@import '../../style/variables.scss';

.radioGroup {
  margin-left: 24px;
  width: 85px;

  @media(max-width: 890px) {
   margin-left: unset;
  }

  @media(max-width: 480px) {
    // width: auto;
    width: 100%;
  }

  &_margin {
    @media(max-width: 480px) {
      padding-left: 12px;
    }
  }

  .radio {
    transition-duration: 0.7s;

    @media(max-width: 480px) {
      width: auto;
      text-align: center;
    }

    &:first-of-type {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &:last-of-type {
      border-radius: 0 $border-radius $border-radius 0;
    }

    &:only-child {
      border-radius: $border-radius;
    }
  }

  :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
    background: $color-yellow !important;
    border-color: $color-button-grey !important;
    // font-weight: 700 !important;
  }

  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
    background-color: unset !important;
  }

  :global(.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
    box-shadow: unset !important;
  }

  
}
