@import '../../../style/variables.scss';

.menuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  padding: 70px 13px 0;
  background-color: rgba(255, 255, 255, 0.96);
  transition: all 500ms;
  overflow-y: scroll;

  &.opened {
    z-index: 100500;
    // margin-top: 80px;
    visibility: visible;
    opacity: 1;
  }

  .userProfile {
    padding-left: 24px;
    width: fit-content;
  }

  :global(.ant-menu-item-selected) {
    a {
      color: $color-green;
    }
    &::after {
      border-bottom: 2px solid $color-green !important;
    }
  }

  :global(.ant-menu-item-active) {
    a {
      color: $color-green;
    }
  }
  :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
    &:active {
      background: $color-background-grey;
    }
  }
  :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
    background: $color-background-grey;
  }
  :global(.ant-menu-inline.ant-menu-root .ant-menu-item:active) {
    background: $color-background-grey;
  }
  :global(.ant-menu-inline .ant-menu-item::after) {
    border-right: 3px solid #935cec94;
  }
}

.menuIcon {
  // z-index: 100501 !important;
  margin-left: 20px;
  padding-top: 18px;
  font-size: 60px;
  transition: all 200ms;
  cursor: pointer;

  @media (min-width: 890px) {
    display: none;
  }

  span {
    z-index: 100501;
    display: block;
    margin-bottom: 6px;
    transform-origin: left;
    width: 20px;
    height: 2px;
    background-color: #000;
    transition: all 200ms;
  }

  &.opened {
    z-index: 3000;

    span {
      background-color: #171717;

      &:nth-child(1) {
        transform: rotate(45deg) translateY(-4px);
      }
      &:nth-child(2) {
        transform: rotate(-45deg) translateY(4px);
      }
    }
  }
}
