@import '../../style/variables.scss';

.input {
  height: 48px;
  //     width: 392px;
  z-index: 1;
  background: transparent !important;
  
      border-radius: 8px !important;
      &:focus {
        border: 1px solid $color-green;
      }
}

.minorInput {
  // background: url('/dollar-symbol.svg') no-repeat 95% !important;
  // background-position: right;
  // background-size: 15px !important;
  height: 48px;
  width: 165px;
}

.requiredInput {
  border-color: red;
}

.floatLabel {
  position: relative;
  order: -1;
  //CHANGED
  // margin-bottom: 7px;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.asPlaceholder {
  position: absolute;
  top: 12px;
  left: 10px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 0.3;
  z-index: 0;
}

.asLabel {
  // top: -8px;
  // font-size: 12px !important;
  // background: white;
  // padding: 0 4px;
  // margin-left: -4px;
  //CHANGED
  color: $color-font-grey !important;
  font-size: 10px !important;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 4px;
  transition: 0.2s ease all;
}

// .float-label {
//   position: relative;
//   margin-bottom: 12px;
// }

// .label {
//   color: $color-font-grey !important;
//   font-size: 17px;
//   font-weight: normal;
//   position: absolute;
//   pointer-events: none;
//   left: 12px;
//   top: 18px;
//   transition: 0.2s ease all;
// }

// .labelFloat {
//   top: 3px;
//   font-size: 10px !important;
// }
