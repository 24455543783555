@import './fonts.scss';


// $font-main: 'FiraSans-Regular', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
// $font-main: 'Helvetica Neue', BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
$font-main: 'Inter', sans-serif;
// $font-main: 'Nunito Sans', sans-serif;
// $font-main: 'Mulish', sans-serif;
// $font-main: 'Manrope', sans-serif;


// $content-width: 1050px;

// $desktop-width: 1200px;
// $laptop-width: 1280px;
// $tablet-width: 960px;
// $mobile-width: 760px;
// $xs-mobile-width: 430px;
// $xxs-mobile-width: 320px;

$color-white: #fff;
$color-black: #32343c;
$color-red: rgba(254, 74, 106, 1);
$color-green-button: #43BA00;
$color-green: rgba(49, 194, 124, 1);
$color-dark-green: rgba(15, 138, 15, 1);
$color-yellow: rgba(255, 230, 153, 1);

// $color-gradient: linear-gradient(90deg, #6371ed 0%, #935cec 100%);
// $color-hover-gradient: linear-gradient(90deg, #6371ede6 0%, #935cecd4 100%);
// $color-active-gradient: linear-gradient(90deg, #4e5eef 0%, #8c4ef0 100%);

$color-gradient: linear-gradient(90deg, #43BA00 0%, #338a00 100%);
$color-hover-gradient: linear-gradient(90deg, #77e438 0%, #4eb611 100%);
$color-active-gradient: linear-gradient(90deg, #3b9a04 0%, #265908 100%);

$color-main: #43BA00;
// $color-main: #0f8a0f;
$color-hover-main: #338a00;
$color-active-main: #358009;

$color-font-grey: #33445580;
$color-background-grey: #f4f4f6;
$color-button-grey: rgba(30, 34, 72, 0.1);
$color-hover-grey: rgb(51 68 85 / 13%);
$color-hover-light: #fafafa;

$box-shadow-table: 0px 7px 10px rgba(78, 98, 174, 0.05);
$box-shadow-input: 2px #6f7cede6 !important;
$border-radius: 4px;
$border-radius-table: 8px;
