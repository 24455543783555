@import '../../style/variables.scss';

.landing {

  margin: 0 4px;
  
  .giftCards {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 5%;
    width: 70vw;
    height: 80vh;
    max-width: 882px;
    max-height: 661px;
    border-radius: 50%;
    background: linear-gradient(to right, rgb(173, 237, 255), rgba(230, 255, 223), rgba(230, 255, 223));
    filter: blur(2rem);
    opacity: 0.5;
  }

    h1 {
      width: 50vw;
      font-size: 56px;
      font-weight: bold;
      margin: 0 48px;
      line-height: 1.2em;
      text-align: center;

      span {
        color: #1EA112;
      }

      @media (max-width: 768px) {
        width: 90vw;
        font-size: 36px;
      }
    }

    &__currenciesBlock {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;
      

      .currencies {
         
        height: 24px;
        width: 48px;
        margin: 6px 4px 4px 4px;
        background: #FFCC33;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        
      }
    }

    &__availableWithdraw {

     margin: 24px 0;
     display: flex;
    //  flex-wrap: nowrap;
     justify-content: center;

    //  width: 40vw;
        margin: unset;
        overflow-x: scroll;
        white-space: nowrap;
        scrollbar-width: none;

     @media (max-width: 768px) {
        //  margin: 12px 0;
        width: 100%;
        margin: unset;
        overflow-x: scroll;
        white-space: nowrap;
        justify-content: flex-start;
        scrollbar-width: none;
    }
    

      &_cards {
        margin: 8px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;


        height: 210px;
        background-color: white;
        
        border-radius: 8px;
        min-width: 169px;

        filter: drop-shadow(0px 1px #D9D9D9);

        
      }

      &_rates {
        text-align: center;

        .boldSpan {
          font-weight: bold;
        }

        span span {
          color: #43BA00;
        }
  
      }
    }

    
  }

  .questions {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 96px 0;
    width: 100%;

    @media (max-width: 768px) {
      margin: 12px 0;
     }
    

    &__faq {
     margin: 24px 0;
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content: center;

     &_card {
        margin: 8px 8px;
        padding: 24px;
        display: flex;
        flex-direction: column;

        height: max-content;
        width: 259px;
        border-radius: 8px;

        @media (max-width: 825px) {
          width: 70vw;
        }

        @media (max-width: 476px) {
          width: 95vw;
        }

        h3 {
          font-weight: bold;
        }

        &_card_0 {
          background-color: #FFF4CC;
        }
        
        &_card_1 {
          background-color: #E5F9FF;
        }
        
        &_card_2 {
          background-color: #E7FFDB;
        }
     }
    }

  }

  .reviews {

    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin: 96px 0;

    @media (max-width: 768px) {
      margin: 12px 0;
     }

  &__cards {
    max-width: 960px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

  }


  &__cards_review {

    width: 25vw;
    max-width: 280px;
    box-sizing: border-box;
    padding: 24px;
    

    position: relative;
    overflow: hidden;

    @media (max-width: 825px) {
      width: 40vw;
    }

    @media (max-width: 476px) {
      width: 95vw;
      max-width: unset;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 16px; 
      padding: 1px; 
      background:linear-gradient(to bottom,var(--review-color),white); 
      -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }

    &_bio {
      display: flex;
      flex-direction: row;
      align-items: center;
      // flex-wrap: wrap;
      // margin-bottom: 18px;
      // justify-content: space-around;

      &_names {
        margin-left: 18px;
        display: flex;
      flex-direction: column;

        span {
          font-weight: bold;
        }

        small {
          font-size: 12px;
          opacity: 0.7;
        }
        
        
      }
    }

    }
  

  }

  .button {

      align-items: center;
      appearance: none;
      background-color: #43BA00;
      border: none;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline - flex;
      font-size: 18px;
      font-weight: 700;
      height: 48px;
      justify-content: center;
      line-height: 64px;
      min-width: 200px;
      padding: 0 32px;
      position: relative;
      transition: all .15s ease;
      -webkit-user-select: none;
      user-select: none;
      vertical-align: middle;

      &:hover {
        color: white !important;
        background: $color-hover-gradient !important;
      }
    
    
  }

  .buttonOld {
    background-color: #43BA00;
    border-radius: 8px;
    border: none;

    width: 248px;
    height: 48px;

    span {
      font-size: 18px;
      font-weight: bold;
      color: white;
    }
  }

  h2 {
    width: 50vw;
    font-size: 36px;
    font-weight: bold;
    margin: 24px 48px;
    line-height: 1.2em;
    text-align: center;
    // align-self: center;

    span {
      color: #1EA112;
    }

    @media (max-width: 768px) {
      width: 70vw;
        // font-size: 24px;
    }
  }

    .register {

    margin: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    
  }

  .showAllButton {
    position: relative;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    color: inherit;
    cursor: pointer;

    font-size: 16px;

    
  }

  .rate > li {
    margin-right: 1px;
  }
  
}
