// @font-face {
//   font-family: 'FiraSans-Regular';
//   src: url('./fonts/FiraSans-Regular.ttf') format('ttf');
//   font-display: swap;
// }

// @font-face {
//   font-family: sans-serif;
//   font-display: swap;
// }
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Inter', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@300;400;500;600;700&display=swap') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@300;400;500;600;700&display=swap') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@300;400;500;600;700&display=swap') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@300;400;500;600;700&display=swap') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@300;400;500;600;700&display=swap') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./fonts/HelveticaNeueCyr-Light.woff2') format('woff2');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./fonts/HelveticaNeueCyr-Roman.woff2') format('woff2');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./fonts/HelveticaNeueCyr-Medium.woff2') format('woff2');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Helvetica Neue';
//   src: url('./fonts/HelveticaNeueCyr-Bold.woff2') format('woff2');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }





