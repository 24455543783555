@import '../../style/variables.scss';

.error {
  :global(.ant-message-notice-content) {
    padding: 16px 24px;
    background: $color-red;
    border-radius: $border-radius-table;
    svg {
      fill: $color-white !important;
    }
    span {
      color: $color-white !important;
      font-size: 19px;
      font-weight: 700;
    }
  }
}

.success {
  :global(.ant-message-notice-content) {
    padding: 16px 24px;
    background: $color-green;
    border-radius: $border-radius-table;
    svg {
      fill: $color-white !important;
    }
    span {
      color: $color-white !important;
      font-size: 19px;
      font-weight: 700;
    }
  }
}
