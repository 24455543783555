@import '../../style/variables.scss';


.signin {
  color: $color-black !important;
  margin-left: 10px;

  &:hover {
    color: $color-green !important;
  }
}

.header {
  display: flex;
  // padding: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h1 {
    font-size: 38px;
    font-weight: 700;
  }

  a {
    height: 36px;
    font-size: 16px;
  }

  // @media (max-width: 390px) {
  //   width: 75vw;
  // }
}

.registerForm {
    width: 500px;
    margin: auto;
    border-radius: $border-radius-table;
    background: #FFFFFF;
    // box-shadow: $box-shadow-table;
    box-shadow: 0px 16px 40px 0px rgba(0, 64, 128, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 36px;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 5%;
      width: 70vw;
      height: 80vh;
      max-width: 882px;
      max-height: 661px;
      border-radius: 50%;
      background: linear-gradient(to right, rgba(228, 255, 223, 1), rgba(223, 255, 249, 1));
      filter: blur(2rem);
      opacity: 0.5;
    }

    @media(max-width: 540px) {
      width: auto;
    }

    .registerInput {
      height: 48px;
      width: 450px;
      border-radius: 8px;
      font-size: 16px;

      @media(max-width: 540px) {
        width: 90vw;
      }
    }

    

    .registerInputEmail {
      height: 48px;
      width: 450px;
      border-radius: 8px;
      &:focus {
        border: 1px solid $color-green;
      }
    }

    .textValidated {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      margin-bottom: 15px;

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        // opacity: 0.5;
      }
    }

    .text {
      margin-bottom: 30px;

      .link {
        float: right;
      }

      .textLink {
        font-weight: bold;
        color: black;
        &:hover {
          cursor: pointer;
          color: $color-green
        }
      }
    }
    .button {
      // margin-top: 20px;
      width: 100%;
      height: 56px;
      border-radius: 8px;
      background: $color-green-button;
      transition: all 0.7s ease-out;
      align-self: center;

      span {
        color: $color-white !important;
        font-size: 20px;
        font-weight: 700;
      }

      @media(max-width: 515px) {
        width: 100%;
      }

      &:hover {
        background: $color-hover-gradient !important;
        background-size: 300%;
        animation: gradient 1s ease;
        transform: translate3d(0, 0, 0);
      }

      &:active {
        background: $color-dark-green;
      }
    }

    .buttonAnimated {
      margin-bottom: 20px;
      width: 70px;
      height: 70px;
      border-radius: 70px;
      background: $color-green-button;
      margin-left: 172px;
    }

    .animateButton:focus {
      animation: shake 0.5s;
    }


    .text {
      display: flex;
      justify-content: space-between;
      
    }
   
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

@keyframes ldio4nb5p2ohc8k {
  0% { transform: translate(6px,40px) scale(0); }
 25% { transform: translate(6px,40px) scale(0); }
 50% { transform: translate(6px,40px) scale(1); }
 75% { transform: translate(40px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(1); }
}
@keyframes ldio4nb5p2ohc8kr {
  0% { transform: translate(74px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(0); }
}
@keyframes ldio4nb5p2ohc8kc {
  0% { background: white }
 25% { background: white }
 50% { background: white }
 75% { background: white }
100% { background: white }
}
.ldio4nb5p2ohc8k div {
 position: absolute;
 width: 12px;
 height: 12px;
 border-radius: 50%;
 transform: translate(40px,40px) scale(1);
 background: white;
 animation: ldio4nb5p2ohc8k 2s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio4nb5p2ohc8k div:nth-child(1) {
 background: white;
 transform: translate(74px,40px) scale(1);
 animation: ldio4nb5p2ohc8kr 0.5s infinite cubic-bezier(0,0.5,0.5,1), ldio4nb5p2ohc8kc 2s infinite step-start;
}.ldio4nb5p2ohc8k div:nth-child(2) {
 animation-delay: -0.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(3) {
 animation-delay: -1s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(4) {
 animation-delay: -1.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(5) {
 animation-delay: -2s;
 background: white;
}
.loadingiospinnerellipsisxxraaph2hu9 {
 width: 56px;
 height: 56px;
 margin-left: -4px;
 overflow: hidden;
 background: none;
}
.ldio4nb5p2ohc8k {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(0.56);
 backface-visibility: hidden;
 transform-origin: 0 0; /* see note above */
}
.ldio-4nb5p2ohc8k div { box-sizing: content-box; }
