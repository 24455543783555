.giftCards__currenciesBlock {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    // text-decoration: line-through;

    &_select {
      width: 65px;
      height: 30px;
      margin: 0 8px;
      border-radius: 4px;
      text-align: center;
      border: 1px solid rgb(210, 210, 210);
      
    }
    

    .currencies {
       
      height: 24px;
      width: 48px;
      margin: 6px 4px 4px 4px;
      background: #FFCC33;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      
  }
}

.giftCards__availableWithdraw_default {
  width: 100vw;
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &_cards {
    // width: 15vw;
    padding: 12px;
     margin: 8px 4px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: space-evenly;

     width: 25vw;
     max-width: 200px;
     height: 256px;
     background-color: white;
     
     border-radius: 8px;
    //  min-width: 169px;

     filter: drop-shadow(0px 1px #D9D9D9);

     @media (max-width: 820px) {
      margin: 8px 4px;
      width: 95%;
      // min-width: 169px;
       // height: 30vw;
       // width: 40vw;
       // max-width: 169px;
       // min-height: 125px;
   
     }

     @media (max-width: 520px) {
      width: 95%;
      // min-width: 169px;
     //  padding-top: 18px;
      // margin: 0 90px;
     }
    }

}

.giftCards__availableWithdraw {

  // width: 90vw;
  position: relative;
  margin: 24px 0;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // transition: all 1s ease-out;

  // scroll-behavior: smooth;

    width: 85vw;
    max-width: 800px;
    // height: 100px;
    //  margin: unset;
    //  overflow-x: hidden;
    //  white-space: nowrap;
    //  scrollbar-width: none;
    //  &::before , 
    //  &::after {
    //    content:'';
    //    flex:1;
    //  }

    //  &::-webkit-scrollbar {
    //   display: none;
    // }

  @media (max-width: 820px) {
    // max-width: 600px;
    // width: 80vw;
    // width: 65vw;
     //  margin: 12px 0;
    //  width: 100%;
    //  margin: unset;
    //  overflow-x: hidden;
    //  white-space: nowrap;
    //  justify-content: flex-start;
    //  scrollbar-width: none;
 }

 @media (max-width: 520px) {

  width: 100vw;
  //  padding-top: 18px;
  //  margin: 4px 4px;
  }
 
  
   &_cards {
    // width: 15vw;
    
    // transition-duration: 1s;
    
    padding: 12px;
     margin: 8px 4px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: space-evenly;

     max-width: 200px;
     height: 256px;
     background-color: white;
     
     border-radius: 8px;
    //  min-width: 169px;

     filter: drop-shadow(0px 1px #D9D9D9);

     @media (max-width: 820px) {
      margin: 8px 4px;
      width: 95%;
      // min-width: 169px;
       // height: 30vw;
       // width: 40vw;
       // max-width: 169px;
       // min-height: 125px;
   
     }

     @media (max-width: 520px) {
      width: 95%;
      // min-width: 169px;
     //  padding-top: 18px;
      // margin: 0 90px;
     }

     

     
   }

   &_rates {
     text-align: center;

     .boldSpan {
       font-weight: bold;
     }

     span span {
       color: #43BA00;
     }

   }
 }


 .custom_slider__input {
  position: relative;
  // width: 65vw;
  // left: -110px;
  top: -160px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // padding: 0 30vw;
  // // width: 80vw;
  // top: 80px;
  // left: -85px;
  // width: 100px;
 }

 .ratesWrapper {
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
 }

 .boldSpan {
  display: flex;
  justify-content: space-around;

  width: 100%;
  text-align:start;
  border-top: 1px solid #D9D9D9;
  padding: 8px 0 8px 0;
  font-size: 14px;

  &:last-child {
    padding: 8px 0 0 0;
  }

  .label {
    flex: 1;
  }

  .value {
    flex: 1;
  }
 }

 .carousel_container_with_scrollbar {
  touch-action: none;
 }


