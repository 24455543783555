@import '../../style/variables.scss';

.verifyLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  min-height: 150px;

  &__button {
    border-radius: 4px;
    width: 250px;
    height: 150px;
    padding: 8px 12px;
    background-color: $color-green-button;
    font-weight: 700;
    color: white;
    &:hover {
      color: white !important;
      background: $color-hover-gradient;
    }
    
  }

  svg[data-icon="check-circle"]{
    fill: #52c41a !important
  }

  svg[data-icon="close-circle"]{
    fill: #ff4d4f !important
  }
}


