@import '../../style/variables.scss';

.wrapper {
  position: relative;
  // z-index: 999;
  display: flex;
  flex-direction: row;

  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    
  }

  .signOutButton {
  width: 187px;
  height: 40px;
  border-radius: $border-radius;
  background: $color-gradient;
  transition: all 0.7s ease-out;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  &:hover {
    background: $color-hover-gradient !important;
    background-size: 300%;
    animation: gradient 1s ease;
    transform: translate3d(0, 0, 0);
  }

  &:active {
    background: $color-active-gradient;
  }

  .signin {
    color: $color-white !important;
    font-size: 17px;
    font-weight: 600;
  }
  }

  .buttonAnimated {
    // margin-top: 20px;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background: $color-gradient;
    // margin-left: 250px;
  }
  
  .button {
  // width: 25%;
  height: 48px;
  border-radius: $border-radius;
  background: $color-green-button;
  transition: all 0.7s ease-out;
  align-self: center;
  // margin-bottom: 32px;

  span {
    color: $color-white;
    font-size: 17px;
    font-weight: 600;
  }

  &:hover {
    background: $color-hover-gradient !important;
    background-size: 300%;
    animation: gradient 1s ease;
    transform: translate3d(0, 0, 0);
  }

  &:active {
    background: $color-active-gradient;
  }

  // &:disabled {
  //   background: rgba(30, 34, 72, 0.05) !important; 
  //   span {
  //     color: rgba(51, 68, 85, 0.3) !important
  //   }
  // }
  }


  .userProfile {
    // width: 307px;
    width: 100%;
    height: auto;
    padding: 24px;
    // max-height: 341px;
    border-radius: $border-radius-table;
    background: #FFFFFF;
    box-shadow: $box-shadow-table;
    margin-right: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 540px) {
      width: auto;
      margin-right: unset;
      margin-bottom: 16px;
      align-items: center;
    }

    .userName {
      
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .refferal {
      
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  

  .passwordForm {
    // width: 968px;
    width: 100%;
    height: auto;
    border-radius: $border-radius-table;
    background:#FFFFFF;
    box-shadow: $box-shadow-table;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    

    @media (max-width: 540px) {
      width: auto;
    }

    .radioGroup {
      
      margin-top: 40px;
      width: 527px;

      .radio {
        height: 48px;
        width: 80px;
        text-align: center;
        transition-duration: 0.7s;

        span {
          line-height: 45px;
          font-weight: 600;
          font-size: 17px;
        }

        &:first-of-type {
          border-radius: $border-radius 0 0 $border-radius;
        }

        &:last-of-type {
          border-radius: 0 $border-radius $border-radius 0;
        }

        &:only-child {
          border-radius: $border-radius;
        }
      }

      :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
        background: linear-gradient(90deg, #6371ED 0%, #935CEC 100%) !important;
        border-color: $color-button-grey !important;
        font-weight: 700 !important;
        span {
          color: $color-white !important;
        }
      }

      :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
        background-color: unset !important;
      }

      :global(.ant-radio-group-solid
          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
        box-shadow: unset !important;
      }
    }
    
    .walletInput {
      height: 56px;
      border-radius: $border-radius-table;
      width: 527px;

      @media (max-width: 540px) {
        width: 300px;
        
      }
      

      &:hover {
        border: 3px solid #6371ED;
      }

      &:focus {
        border: 3px solid #6371ED;
      }
    }

    .textValidated {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      margin-bottom: 15px;

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        // opacity: 0.5;
      }
    }


  }
}

@keyframes ldio4nb5p2ohc8k {
  0% { transform: translate(6px,40px) scale(0); }
 25% { transform: translate(6px,40px) scale(0); }
 50% { transform: translate(6px,40px) scale(1); }
 75% { transform: translate(40px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(1); }
}
@keyframes ldio4nb5p2ohc8kr {
  0% { transform: translate(74px,40px) scale(1); }
100% { transform: translate(74px,40px) scale(0); }
}
@keyframes ldio4nb5p2ohc8kc {
  0% { background: white }
 25% { background: white }
 50% { background: white }
 75% { background: white }
100% { background: white }
}
.ldio4nb5p2ohc8k div {
 position: absolute;
 width: 12px;
 height: 12px;
 border-radius: 50%;
 transform: translate(40px,40px) scale(1);
 background: white;
 animation: ldio4nb5p2ohc8k 2s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio4nb5p2ohc8k div:nth-child(1) {
 background: white;
 transform: translate(74px,40px) scale(1);
 animation: ldio4nb5p2ohc8kr 0.5s infinite cubic-bezier(0,0.5,0.5,1), ldio4nb5p2ohc8kc 2s infinite step-start;
}.ldio4nb5p2ohc8k div:nth-child(2) {
 animation-delay: -0.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(3) {
 animation-delay: -1s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(4) {
 animation-delay: -1.5s;
 background: white;
}.ldio4nb5p2ohc8k div:nth-child(5) {
 animation-delay: -2s;
 background: white;
}
.loadingiospinnerellipsisxxraaph2hu9 {
 width: 56px;
 height: 56px;
 margin-left: -4px;
 overflow: hidden;
 background: none;
}
.ldio4nb5p2ohc8k {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(0.56);
 backface-visibility: hidden;
 transform-origin: 0 0; /* see note above */
}
.ldio-4nb5p2ohc8k div { box-sizing: content-box; }

