@import '../../style/variables.scss';

.signin {
  // color: $color-black;
  // font-weight: 700;
  // margin-left: 10px;

  &:hover {
    cursor: pointer;
    color: $color-green !important;
  }
}
