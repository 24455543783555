// @import '../../style/variables.scss';


.footerContainer {
  // width: 960px;
  // width: 85vw;
  // margin: 24px 10vw;
  // max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: 96px;

  
}


.footerNew {
  // width: 100%;
  // width: 960px;
  // margin: 48px 4px;
}

// .footerNew {
//   @media (max-width: 768px) {
//     margin: 0 24px;
//   }
//   @media (max-width: 480px) {
//     padding: 12px;
//     width: 320px;
//   }
// }

.footerNew p {
  font-size: 14px;
  line-height: 100%;
  margin-bottom: 8px !important;
}

// .footerClapswap {
//   padding: 16px 0px 0px;
//   gap: 24px;

//   width: 960px;

//   @media(max-width: 967px) {
//     width: 480px;
//     // max-width: 450px;
//     padding: 0px;
//     gap: unset;
//     display: flex;
//     // justify-content: center;
//   }

//   @media(max-width: 480px) {
//     width: 343px;
    
//   }
// }

.footerLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 960px;

  @media(max-width: 967px) {
    width: 480px;
    // max-width: 450px;
    flex-direction: column;
    gap: unset;
    
    // align-items: center;
  }

  @media(max-width: 480px) {
    width: 343px;

    // p {
    //   margin-bottom: 48px !important;
    // }
  }
}
