@import '../../style/variables.scss';

.radioGroup {
  margin-bottom: 24px;
  
  width: auto;

  @media(max-width: 480px) {
    // width: auto;
    width: 100%;
  }

  &_margin {
    @media(max-width: 480px) {
      padding-left: 12px;
    }
  }

  .radio {
    // height: 44px;
    // line-height: 18px;
    // text-align: center;
    // padding-top: 15px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    transition-duration: 0.7s;

    @media(max-width: 480px) {
      width: auto;
      text-align: center;
    }

    &:first-of-type {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &:last-of-type {
      border-radius: 0 $border-radius $border-radius 0;
    }

    &:only-child {
      border-radius: $border-radius;
    }
  }

  :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
    background: $color-yellow !important;
    border-color: $color-button-grey !important;
    // font-weight: 700 !important;
  }

  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
    background-color: unset !important;
  }

  :global(.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
    box-shadow: unset !important;
  }

  
}
